import {useMemo, useRef, useEffect, useState, useCallback} from 'react';
import {scaleLinear, scaleTime} from '@visx/scale';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import {mmolPerLToMgPerdL} from '../../../helpers';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import moment from 'moment';
import {timeSampConvert} from '../utils';
import styled from '@emotion/styled';
import {Grid} from '@mui/material';
import {DynamicData} from '../../../api/service-types';

interface DataPoint {
  x: string;
  y: number;
}
interface BloodGlucoseChartProps {
  cgmMeasurements: DynamicData['cgmMeasurements'];
  crosshairTime: number | null;
  setCrosshairTime: (time: number | null) => void;
}

export const BloodGlucoseChart = ({
  cgmMeasurements,
  crosshairTime,
  setCrosshairTime,
}: BloodGlucoseChartProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(1000);
  const [tooltipData, setTooltipData] = useState<DataPoint | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number; y: number} | null>(null);

  const {unit} = useAppSelector(unitSelector);
  const convertToMmol = useCallback((value: number) => (unit === 'mmol/L' ? value / 18.018 : value), [unit]);

  const dataCombined = useMemo(
    () =>
      cgmMeasurements.map(cgm => ({
        x: timeSampConvert(cgm.eventUTCInstant),
        y: mmolPerLToMgPerdL(cgm.measurement, unit),
      })),
    [cgmMeasurements, unit],
  );

  const minX = useMemo(() => Math.min(...dataCombined.map(d => new Date(d.x).getTime())), [dataCombined]);
  const maxX = useMemo(() => Math.max(...dataCombined.map(d => new Date(d.x).getTime())), [dataCombined]);
  const minY = 0;
  const maxY = convertToMmol(400);
  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};

  const xScale = useMemo(
    () =>
      scaleTime({
        domain: [minX, maxX],
        range: [margin.left, width - margin.right],
      }),
    [minX, maxX, width, margin.left, margin.right],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [minY, maxY],
        range: [height - margin.bottom, margin.top],
      }),
    [minY, maxY, height, margin.bottom, margin.top],
  );

  const ranges = useMemo(
    () => [
      {min: 0, max: convertToMmol(54), color: '#940106'},
      {min: convertToMmol(54), max: convertToMmol(70), color: '#FF1C01'},
      {min: convertToMmol(70), max: convertToMmol(180), color: '#47D120'},
      {min: convertToMmol(180), max: convertToMmol(250), color: '#FADA28'},
      {min: convertToMmol(250), max: convertToMmol(400), color: '#FF9D00'},
    ],
    [convertToMmol],
  );

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
      const {clientX} = event;
      const svgRect = svgRef.current?.getBoundingClientRect();
      if (!svgRect) return;

      const mouseXPosition = clientX - svgRect.left;

      const closestDatum = dataCombined.reduce((prev, curr) => {
        const prevDistance = Math.abs(xScale(new Date(prev.x)) - mouseXPosition);
        const currDistance = Math.abs(xScale(new Date(curr.x)) - mouseXPosition);
        return currDistance < prevDistance ? curr : prev;
      });

      const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();
      setCrosshairTime(timeAtMousePosition);
      setTooltipData(closestDatum);
      setTooltipPosition({
        x: xScale(new Date(closestDatum.x)),
        y: yScale(closestDatum.y),
      });
    },
    [dataCombined, xScale, yScale, setCrosshairTime],
  );

  const handleMouseLeave = useCallback(() => {
    setTooltipData(null);
    setTooltipPosition(null);
    setCrosshairTime(null);
  }, [setCrosshairTime]);

  const renderCircles = useCallback(
    (data: DataPoint[]) =>
      data.map((d, i) => (
        <circle
          key={`circle-${i}`}
          cx={xScale(new Date(d.x))}
          cy={yScale(d.y)}
          r={3}
          fill={ranges.find(range => d.y >= range.min && d.y < range.max)?.color || 'gray'}
          onMouseMove={() => {
            setTooltipData(d);
            setTooltipPosition({
              x: xScale(new Date(d.x)),
              y: yScale(d.y),
            });
          }}
          onMouseLeave={() => {
            if (tooltipData === d) {
              setTooltipData(null);
              setTooltipPosition(null);
            }
          }}
        />
      )),
    [ranges, xScale, yScale, tooltipData],
  );

  const crosshairXPosition = useMemo(
    () => (crosshairTime ? xScale(new Date(crosshairTime)) : null),
    [crosshairTime, xScale],
  );

  const ChartLabel = styled('h5')(() => ({
    display: 'flex',
    alignItems: 'center',
    transform: 'rotate(270deg)',
    padding: 0,
  }));

  return (
    <Grid container xs={12} spacing={2} mt={2}>
      <Grid xs={0.5} display={'flex'} padding={0}>
        <ChartLabel>{unit}</ChartLabel>
      </Grid>
      <Grid xs={11.5}>
        <div style={{position: 'relative'}}>
          <svg ref={svgRef} width="100%" height={height}>
            <Group>
              {/* Green background */}
              <rect
                x={margin.left}
                y={yScale(convertToMmol(180))}
                width={width - margin.left - margin.right}
                height={yScale(convertToMmol(70)) - yScale(convertToMmol(180))}
                fill="rgba(0, 128, 0, 0.08)"
              />
              <line
                x1={margin.left}
                x2={width - margin.right}
                y1={yScale(convertToMmol(180))}
                y2={yScale(convertToMmol(180))}
                stroke="rgba(0, 128, 0, 0.15)"
                strokeWidth={2}
              />
              <line
                x1={margin.left}
                x2={width - margin.right}
                y1={yScale(convertToMmol(70))}
                y2={yScale(convertToMmol(70))}
                stroke="rgba(0, 128, 0, 0.15)"
                strokeWidth={2}
              />

              {/* Draw grid */}
              <GridColumns scale={xScale} height={height - margin.bottom} strokeDasharray="2,2" left={margin.left} />
              <GridRows scale={yScale} width={width - margin.right} strokeDasharray="2,2" top={margin.top} />

              {/* Draw axes */}
              <AxisBottom
                top={height - margin.bottom}
                scale={xScale}
                tickLabelProps={() => ({
                  fill: 'black',
                  fontSize: 11,
                  textAnchor: 'middle',
                })}
              />
              <AxisLeft
                scale={yScale}
                left={margin.left}
                tickLabelProps={() => ({
                  fill: 'black',
                  fontSize: 11,
                  textAnchor: 'end',
                })}
              />

              {/* Draw vertical crosshair */}
              {crosshairXPosition !== null && (
                <line
                  x1={crosshairXPosition}
                  x2={crosshairXPosition}
                  y1={margin.top}
                  y2={height - margin.bottom}
                  stroke="gray"
                  strokeDasharray="4,4"
                />
              )}

              {/* Draw scatter points */}
              {renderCircles(dataCombined)}

              {/* Capture mouse events */}
              <rect
                x={margin.left}
                y={margin.top}
                width={width - margin.left - margin.right}
                height={height - margin.bottom}
                fill="transparent"
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
              />
            </Group>
          </svg>

          {tooltipData && tooltipPosition && (
            <div
              style={{
                position: 'absolute',
                left: `${tooltipPosition.x - 150}px`,
                top: `${tooltipPosition.y - 20}px`,
                backgroundColor: 'white',
                padding: '5px 10px',
                pointerEvents: 'none',
                transform: 'translate(-50%, -100%)',
                boxShadow: '1px 1px 1px 1px #757575',
                borderRadius: '4px',
                zIndex: '99',
                textAlign: 'center',
              }}>
              <h5>Glucose Level</h5>
              <p
                style={{
                  fontWeight: 'bold',
                }}>
                {tooltipData.y + ' ' + unit}
              </p>
              <p
                style={{
                  fontSize: '14px',
                }}>
                {moment(tooltipData.x).format('DD-MM-YYYY HH:mm:ss')}
              </p>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
