import {DynamicData} from '../../../api';
import {useRef, useEffect, useState, useMemo} from 'react';
import {scaleTime, scaleLinear} from '@visx/scale';
import {AxisBottom} from '@visx/axis';
import {Group} from '@visx/group';
import {Zoom} from '@visx/zoom';
import {localPoint} from '@visx/event';
import {timeSampConvert} from '../utils';
import correctionBolusIcon from './assets/correctionBolus.svg';
import carbohydratesIcon from './assets/carbohydrates.svg';
import mealBolusIcon from './assets/mealBolus.svg';
import hypoTreatmentIcon from './assets/hypoTreatment.svg';
import {RectClipPath} from '@visx/clip-path';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {Grid} from '@mui/material';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';

interface DataPoint {
  x: string;
  y: number;
  yAmount: number;
  isUnitU: boolean;
  icon: string;
  label: string;
}

interface BolusAndTreatmentChartProps {
  boluses: DynamicData['boluses'];
  mealEvents: DynamicData['mealEvents'];
  cgmMeasurements: DynamicData['cgmMeasurements'];
  crosshairTime: number | null;
  setCrosshairTime: (time: number | null) => void;
}

export const BolusAndTreatmentChart = ({
  boluses,
  mealEvents,
  crosshairTime,
  cgmMeasurements,
  setCrosshairTime,
}: BolusAndTreatmentChartProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(1000);
  const height = 250;
  const margin = {top: 10, right: 20, bottom: 50, left: 60};
  const minY = 0;
  const maxY = 200;

  const mealEventsRef = useRef(mealEvents);
  const carbohydrates: DataPoint[] = mealEventsRef.current
    .filter(event => !event.isHypoTreatment)
    .map(event => ({
      x: timeSampConvert(event.eventUTCInstant),
      y: 150,
      yAmount: event.carbohydratesEstimate,
      icon: carbohydratesIcon,
      label: 'Carbohydrates',
      isUnitU: false,
    }));
  const correctionBoluses: DataPoint[] = mealEventsRef.current
    .filter(event => event.isHypoTreatment)
    .map(event => ({
      x: timeSampConvert(event.eventUTCInstant),
      y: 150,
      yAmount: event.carbohydratesEstimate,
      icon: hypoTreatmentIcon,
      label: 'Correction Bolus',
      isUnitU: false,
    }));

  const bolusesRef = useRef(boluses);
  const mealBolus: DataPoint[] = bolusesRef.current
    .filter(bolus => bolus.mealBolusSafetyNetAmount !== null)
    .map(bolus => ({
      x: timeSampConvert(bolus.eventUTCInstant),
      y: 50,
      yAmount: bolus.mealBolusSafetyNetAmount!,
      icon: mealBolusIcon,
      label: 'Meal Bolus',
      isUnitU: true,
    }));

  const prandialBoluses: DataPoint[] = bolusesRef.current
    .filter(bolus => bolus.correctionBolusSafetyNetAmount !== null)
    .map(bolus => ({
      x: timeSampConvert(bolus.eventUTCInstant),
      y: 50,
      yAmount: bolus.correctionBolusSafetyNetAmount!,
      icon: correctionBolusIcon,
      label: 'Prandial Bolus',
      isUnitU: true,
    }));

  const dataPoints: DataPoint[] = [...carbohydrates, ...correctionBoluses, ...mealBolus, ...prandialBoluses];
  const {unit} = useAppSelector(unitSelector);

  const cgmDataCombined = useMemo(
    () =>
      cgmMeasurements.map(cgm => ({
        x: timeSampConvert(cgm.eventUTCInstant),
      })),
    [cgmMeasurements, unit],
  );

  const minX = useMemo(() => Math.min(...cgmDataCombined.map(d => new Date(d.x).getTime())), [cgmDataCombined]);
  const maxX = useMemo(() => Math.max(...cgmDataCombined.map(d => new Date(d.x).getTime())), [cgmDataCombined]);

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });
  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  const handleMouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    const {clientX} = event;
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = clientX - svgRect.left;
    const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();
    setCrosshairTime(timeAtMousePosition);
  };

  const handleMouseLeave = () => {
    setCrosshairTime(null);
  };

  const renderIconsAndLabels = (data: DataPoint[], xZoomScale: any, yZoomScale: any) =>
    data.map((d, i) => (
      <g key={`item-${i}`}>
        <image href={d.icon} x={xZoomScale(new Date(d.x)) - 30} y={yZoomScale(d.y) - 30} width={60} height={60} />
        <text
          x={xZoomScale(new Date(d.x))}
          y={yZoomScale(d.y) + 35}
          textAnchor="middle"
          fontSize={12}
          fill="black"
          pointerEvents="none">
          {d.isUnitU ? d.yAmount.toFixed(2) + ' U' : d.yAmount.toFixed(0) + ' g'}
        </text>
      </g>
    ));

  const crosshairXPosition = crosshairTime ? xScale(new Date(crosshairTime)) : null;

  const handleCombinedMouseMove = (event: any, zoom: any) => {
    handleMouseMove(event);
    zoom?.dragMove(event);
  };

  const handleCombinedMouseLeave = (event: any, zoom: any) => {
    handleMouseMove(event);
    handleMouseLeave();
    if (zoom?.isDragging) zoom?.dragEnd();
  };

  return (
    <Grid container xs={12} spacing={2} mt={2}>
      <Grid xs={0.5} display={'flex'}></Grid>
      <Grid xs={11.5}>
        <Zoom width={width} height={height} scaleXMin={1 / 2} scaleXMax={4} scaleYMin={1 / 2} scaleYMax={4}>
          {zoom => {
            const {scaleX, scaleY, translateX, translateY} = zoom.transformMatrix;
            const xZoomScale = xScale
              .copy()
              .range([margin.left * scaleX + translateX, (width - margin.right) * scaleX + translateX]);
            const yZoomScale = yScale
              .copy()
              .range([height - margin.bottom * scaleY + translateY, margin.top * scaleY + translateY]);

            return (
              <div>
                <svg
                  ref={svgRef}
                  width="100%"
                  height={height}
                  style={{cursor: zoom.isDragging ? 'grabbing' : 'grab', touchAction: 'none'}}>
                  <RectClipPath id="zoom-clip" width={width} height={height} />
                  <rect width={width} height={height} rx={14} fill={'#fff'} />

                  <Group>
                    <GridColumns
                      scale={xZoomScale}
                      height={height - margin.bottom}
                      strokeDasharray="2,2"
                      left={margin.left}
                    />
                    <GridRows scale={yZoomScale} width={width - margin.right} strokeDasharray="4" top={margin.top} />
                    <AxisBottom
                      top={height - margin.bottom}
                      scale={xZoomScale} // Eixo X atualizado
                      tickLabelProps={() => ({
                        fill: 'black',
                        fontSize: 11,
                        textAnchor: 'middle',
                      })}
                    />

                    {renderIconsAndLabels(dataPoints, xZoomScale, yZoomScale)}

                    {crosshairXPosition !== null && (
                      <line
                        x1={crosshairXPosition}
                        x2={crosshairXPosition}
                        y1={margin.top}
                        y2={height - margin.bottom}
                        stroke="gray"
                        strokeDasharray="4,4"
                      />
                    )}

                    <rect
                      x={margin.left}
                      y={margin.top}
                      width={width - margin.left - margin.right}
                      height={height - margin.top - margin.bottom}
                      fill="transparent"
                      onMouseMove={event => handleCombinedMouseMove(event, zoom)}
                      onTouchStart={zoom.dragStart}
                      onTouchMove={zoom.dragMove}
                      onTouchEnd={zoom.dragEnd}
                      onMouseDown={zoom.dragStart}
                      onMouseUp={zoom.dragEnd}
                      onMouseLeave={event => handleCombinedMouseLeave(event, zoom)}
                      onDoubleClick={event => {
                        const point = localPoint(event) || {x: 0, y: 0};
                        zoom.scale({scaleX: 1.1, scaleY: 1.1, point});
                      }}
                    />
                  </Group>
                </svg>

                <div style={{position: 'absolute', right: 10, top: 10}}>
                  <button onClick={() => zoom.scale({scaleX: 1.2, scaleY: 1.2})}>+</button>
                  <button onClick={() => zoom.scale({scaleX: 0.8, scaleY: 0.8})}>-</button>

                  <button type="button" className="btn btn-lg" onClick={zoom.reset}>
                    Reset
                  </button>
                </div>
              </div>
            );
          }}
        </Zoom>
      </Grid>
    </Grid>
  );
};
