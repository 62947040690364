import {DynamicData} from '../../../api';
import {useRef, useEffect, useState, MouseEvent} from 'react';
import {scaleLinear, scaleTime} from '@visx/scale';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import {Zoom} from '@visx/zoom';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import {curveStepAfter, line} from 'd3-shape';
import {timeSampConvert} from '../utils';
import {Grid} from '@mui/material';
import styled from '@emotion/styled';
import {RectClipPath} from '@visx/clip-path';
import {localPoint} from '@visx/event';
import {Circle} from '@visx/shape';
import moment from 'moment';

interface DataPoint {
  x: string;
  y: number;
}

interface InsulinAndMealsChartProps {
  microboluses: DynamicData['microboluses'];
  crosshairTime: number | null;
  setCrosshairTime: (time: number | null) => void;
}

export const InsulinAndMealsChart = ({
  microboluses,
  crosshairTime,
  setCrosshairTime,
}: InsulinAndMealsChartProps): JSX.Element => {
  const [tooltipData, setTooltipData] = useState<DataPoint | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number; y: number} | null>(null);
  const [width, setWidth] = useState(1000);

  const microbolusesRef = useRef(microboluses);
  const microbolusesData: {x: string; y: number}[] = microbolusesRef.current.map(micro => ({
    x: timeSampConvert(micro.eventUTCInstant),
    y: micro.basalSafetyNetAmount,
  }));

  const svgRef = useRef<SVGSVGElement>(null);
  const minX = Math.min(...microbolusesData.map(d => new Date(d.x).getTime()));
  const maxX = Math.max(...microbolusesData.map(d => new Date(d.x).getTime()));
  const minY = 0;
  const maxY = Math.max(...microbolusesData.map(d => d.y));
  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  const lineGenerator = line<DataPoint>()
    .x(d => xScale(new Date(d.x)))
    .y(d => yScale(d.y))
    .curve(curveStepAfter);

  const handleMouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    const {clientX} = event;
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = clientX - svgRect.left;
    const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();

    const closestDatum = microbolusesData.reduce((prev, curr) => {
      const prevDistance = Math.abs(xScale(new Date(prev.x)) - mouseXPosition);
      const currDistance = Math.abs(xScale(new Date(curr.x)) - mouseXPosition);
      return currDistance < prevDistance ? curr : prev;
    });

    setCrosshairTime(timeAtMousePosition);
    setTooltipData(closestDatum);
    setTooltipPosition({
      x: xScale(new Date(closestDatum.x)),
      y: yScale(closestDatum.y),
    });
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
    setTooltipPosition(null);
    setCrosshairTime(null);
  };

  const handleCombinedMouseMove = (event: any, zoom: any) => {
    handleMouseMove(event);
    zoom?.dragMove(event);
  };

  const handleCombinedMouseLeave = (event: any, zoom: any) => {
    handleMouseMove(event);
    handleMouseLeave();
    if (zoom?.isDragging) zoom?.dragEnd();
  };

  const crosshairXPosition = crosshairTime ? xScale(new Date(crosshairTime)) : null;

  const ChartLabel = styled('h5')(() => ({
    display: 'flex',
    alignItems: 'center',
  }));

  return (
    <Grid container xs={12} spacing={2} mt={2}>
      <Grid xs={0.5} display={'flex'} padding={2}>
        <ChartLabel>U/h</ChartLabel>
      </Grid>
      <Grid xs={11.5}>
        <div style={{position: 'relative'}}>
          <Zoom width={width} height={height} scaleXMin={1 / 2} scaleXMax={4} scaleYMin={1 / 2} scaleYMax={4}>
            {zoom => {
              const {scaleX, scaleY, translateX, translateY} = zoom.transformMatrix;
              const xZoomScale = xScale
                .copy()
                .range([margin.left * scaleX + translateX, (width - margin.right) * scaleX + translateX]);
              const yZoomScale = yScale
                .copy()
                .range([height - margin.bottom * scaleY + translateY, margin.top * scaleY + translateY]);

              return (
                <>
                  <svg
                    ref={svgRef}
                    width="100%"
                    height={height}
                    style={{cursor: zoom.isDragging ? 'grabbing' : 'grab', touchAction: 'none'}}>
                    <RectClipPath id="zoom-clip" width={width} height={height} />
                    <rect width={width} height={height} rx={14} fill={'#fff'} />
                    <Group>
                      <GridColumns
                        scale={xZoomScale}
                        height={height - margin.bottom}
                        strokeDasharray="2,2"
                        left={margin.left}
                      />
                      <GridRows scale={yZoomScale} width={width - margin.right} strokeDasharray="4" top={margin.top} />
                      <AxisBottom
                        top={height - margin.bottom}
                        scale={xZoomScale}
                        tickLabelProps={() => ({
                          fill: 'black',
                          fontSize: 11,
                          textAnchor: 'middle',
                        })}
                      />
                      <AxisLeft
                        scale={yZoomScale}
                        left={margin.left}
                        tickLabelProps={() => ({
                          fill: 'black',
                          fontSize: 11,
                          textAnchor: 'end',
                        })}
                      />
                      <path
                        d={lineGenerator(microbolusesData) || ''}
                        fill="none"
                        stroke="blue"
                        strokeWidth={2 * scaleY}
                      />
                      {crosshairXPosition !== null && (
                        <line
                          x1={crosshairXPosition}
                          x2={crosshairXPosition}
                          y1={margin.top}
                          y2={height - margin.bottom}
                          stroke="gray"
                          strokeDasharray="4,4"
                        />
                      )}
                      {microbolusesData.map((point, index) => {
                        const nextPoint = microbolusesData[index + 1];
                        return (
                          <Circle
                            key={`circle-${index}`}
                            cx={nextPoint ? xZoomScale(new Date(nextPoint.x)) : xZoomScale(new Date(point.x))}
                            cy={yZoomScale(point.y)}
                            r={4} // radius
                            fill="blue"
                          />
                        );
                      })}
                      <rect
                        x={margin.left}
                        y={margin.top}
                        width={width - margin.left - margin.right}
                        height={height - margin.top - margin.bottom}
                        fill="transparent"
                        onTouchStart={zoom.dragStart}
                        onTouchMove={zoom.dragMove}
                        onTouchEnd={zoom.dragEnd}
                        onMouseDown={zoom.dragStart}
                        onMouseMove={event => handleCombinedMouseMove(event, zoom)}
                        onMouseUp={zoom.dragEnd}
                        onMouseLeave={event => handleCombinedMouseLeave(event, zoom)}
                        onDoubleClick={event => {
                          const point = localPoint(event) || {x: 0, y: 0};
                          zoom.scale({scaleX: 1.1, scaleY: 1.1, point});
                        }}
                      />
                    </Group>
                  </svg>
                  <div style={{position: 'absolute', right: 10, top: 10}}>
                    <button onClick={() => zoom.scale({scaleX: 1.2, scaleY: 1.2})}>+</button>
                    <button onClick={() => zoom.scale({scaleX: 0.8, scaleY: 0.8})}>-</button>
                    <button onClick={zoom.reset}>Reset</button>
                  </div>
                </>
              );
            }}
          </Zoom>
          {tooltipData && tooltipPosition && (
            <div
              style={{
                position: 'absolute',
                left: `${tooltipPosition.x - 150}px`,
                top: `${tooltipPosition.y - 20}px`,
                backgroundColor: 'white',
                padding: '5px 10px',
                pointerEvents: 'none',
                transform: 'translate(-50%, -100%)',
                boxShadow: '1px 1px 1px 1px #757575',
                borderRadius: '4px',
                zIndex: '99',
                textAlign: 'center',
              }}>
              <h5>Microbolus</h5>

              <p
                style={{
                  fontWeight: 'bold',
                }}>
                {tooltipData.y.toFixed(2) + ' ' + 'U/h'}
              </p>
              <p
                style={{
                  fontSize: '14px',
                }}>
                {moment(tooltipData.x).format('DD-MM-YYYY HH:mm:ss')}
              </p>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
