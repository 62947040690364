import {useTheme, Typography, Box, Divider, Grid} from '@mui/material';
import {DeviceCard} from '../../../components';
import {GeneralSectionProps} from './types';
import {StyledContainer} from './styles';
import {GraphsWrapper} from '../../../sections/participant/general/GraphsWrapper';
import {mmolPerLToMgPerdL} from '../../../helpers';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {pollingInterval} from '../../../constants/api';
import {measurementsService} from '../../../api/services';
import {useCallback, useEffect, useRef} from 'react';

export const GeneralSection = ({generalRef, dynamicData, staticData}: GeneralSectionProps) => {
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  // const [dynamicDataLastHour, setDynamicDataLastHour] = useState<DynamicData>();

  const {id} = staticData;
  const {devices} = dynamicData;

  const {
    data: latestCgmMeasurements,
    isUninitialized,
    refetch,
  } = measurementsService.useGetLatestCgmMeasurementsQuery(undefined, {
    pollingInterval,
  });
  const refetchTeam = useCallback(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [isUninitialized, refetch]);

  // useEffect(() => {
  //   axios.get<DynamicData>(`${httpsUrl}/participants/screen-data/dynamic/${id}?timeframe=1h`).then(function (response) {
  //     setDynamicDataLastHour(response.data);
  //     console.info(response);
  //   });
  // }, []);

  useEffect(() => {
    const handleInterval = () => {
      console.log('refetch glucose monitor');
      refetchTeam();
    };

    intervalRef.current = setInterval(handleInterval, 300000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const latestCgmMeasurement =
    latestCgmMeasurements &&
    latestCgmMeasurements.find((measurement: {participantId: number}) => measurement.participantId === id);

  const cgmMeasurementsValue = mmolPerLToMgPerdL(latestCgmMeasurement?.measurement ?? 0, unit);

  // const LastHourInsulinBoluses =
  //   dynamicDataLastHour && dynamicDataLastHour.boluses.reduce((acc, item) => acc + (item?.mealBolusPassedSafetyNet ? item?.mealBolusSafetyNetAmount : item.correctionBolusSafetyNetAmount), 0);

  // const LastHourInsulinMicroboluses =
  //   dynamicDataLastHour && dynamicDataLastHour.microboluses.reduce((acc, item) => acc + item.basalSafetyNetAmount, 0);
  // const LastHourInsulinTotal =
  //   LastHourInsulinBoluses && LastHourInsulinMicroboluses && LastHourInsulinBoluses + LastHourInsulinMicroboluses / 12;

  // //Not the last hour, last meal in general
  // const lastHourMealBolusArray = dynamicDataLastHour && Object.values(dynamicDataLastHour.mealEvents);
  // const lastHourMealBolus = lastHourMealBolusArray && lastHourMealBolusArray[lastHourMealBolusArray.length - 1];

  return (
    <StyledContainer ref={generalRef}>
      <Box sx={{boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
        {staticData && (
          <Box sx={{display: 'flex', flexDirection: 'column', mb: '7px'}}>
            <Typography sx={{color: '#9099AA', fontSize: '13px'}}>#{staticData.id}</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Typography sx={{color: '#2B2C2E', fontSize: '20px', fontWeight: '600', marginRight: '16px'}}>
                {staticData.name}
              </Typography>
            </Box>
          </Box>
        )}
        <Divider />
        <Grid container xs={10} lg={12} item spacing={2} mt={0.5} mb={1}>
          {devices[2] && latestCgmMeasurement && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <DeviceCard
                cardHeading="Glucose Monitor"
                showReservoirDetails={false}
                showBatteryPercentage={false}
                lastUpdatedTimeTitle="Last update:"
                lastUpdatedTime={latestCgmMeasurement.eventUTCInstant}
                showReadingValueAndUnit={true}
                readingValue={cgmMeasurementsValue}
                readingUnit={unit}
                readingTrend={latestCgmMeasurement.trend}
              />
            </Grid>
          )}
          {devices[1] && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <DeviceCard
                cardHeading="Pump"
                showReservoirDetails={true}
                reservoirPercentage={devices[1].pumpInsulinPercentage}
                batteryPercentage={devices[1].batteryPercentage}
                lastUpdatedTimeTitle="Last updated timestamp:"
                lastUpdatedTime={devices[1].lastUpdatedTimestamp}
                showReadingValueAndUnit={false}
              />
            </Grid>
          )}
          {/* {LastHourInsulinTotal != 0 && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <DeviceCard
                cardHeading="Insulin"
                showReservoirDetails={false}
                lastUpdatedTimeTitle="Last hour Insulin:"
                readingValue={LastHourInsulinTotal}
                showLastHoursInsulin={true}
                isInsulinCard={true}
                tooltipText="Bolus + Basal"
              />
            </Grid>
          )}
          {lastHourMealBolus && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <DeviceCard
                cardHeading="Meal"
                showReservoirDetails={false}
                lastUpdatedTimeTitle="Last meal bolus:"
                readingValue={lastHourMealBolus.carbohydratesEstimate}
                lastUpdatedTime={lastHourMealBolus.eventUTCInstant}
                showLastCarboMeal={true}
                isInsulinCard={true}
                tooltipText="Carbohydrates"
              />
            </Grid>
          )} */}
        </Grid>
        <GraphsWrapper />
      </Box>
    </StyledContainer>
  );
};
