import {LinePath, Circle} from '@visx/shape';
import {scaleLinear} from '@visx/scale';
import {Grid, Typography} from '@mui/material';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import {useEffect, useRef, useState} from 'react';
import {InsulinSensitivity} from '../../../api';

const InsulinSensitivityChart = (carbohydrateRatios: InsulinSensitivity[]) => {
  const [width, setWidth] = useState(0);
  const height = 300;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const insulinSensitivityI = carbohydrateRatios[0].ratios.map(Number);
  const insulinSensitivityII = carbohydrateRatios[1] && carbohydrateRatios[1]?.ratios.map(Number);

  const yScale = scaleLinear({
    domain: [
      Math.min(...insulinSensitivityI, ...(insulinSensitivityII || [])),
      Math.max(...insulinSensitivityI, ...(insulinSensitivityII || [])),
    ],
    range: [height - margin.bottom, margin.top],
  });

  const xScale = scaleLinear({
    domain: [1, 24],
    range: [margin.left, width - margin.right],
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <svg ref={svgRef} width="100%" height={height}>
          <Group>
            <AxisLeft
              scale={yScale}
              left={margin.left}
              tickFormat={d => d.toString()}
              stroke="black"
              tickStroke="black"
              tickLabelProps={() => ({
                fill: 'black',
                fontSize: 11,
                textAnchor: 'end',
                dx: '-0.25em',
                dy: '0.25em',
              })}
            />

            <AxisBottom
              top={height - margin.bottom}
              scale={xScale}
              stroke="black"
              tickStroke="black"
              tickFormat={d => d.toString()}
              tickLabelProps={() => ({
                fill: 'black',
                fontSize: 11,
                textAnchor: 'middle',
                dy: '0.25em',
              })}
            />

            <LinePath
              data={insulinSensitivityI}
              x={(d, i) => xScale(i + 1) || 0}
              y={d => yScale(d)}
              stroke="purple"
              strokeWidth={2}
            />
            {insulinSensitivityI.map((d, i) => (
              <Circle
                key={`circle-insulinSensitivityI-${i}`}
                cx={xScale(i + 1) || 0}
                cy={yScale(d)}
                r={4}
                fill="purple"></Circle>
            ))}

            {insulinSensitivityII && (
              <LinePath
                data={insulinSensitivityII}
                x={(d, i) => xScale(i + 1) || 0}
                y={d => yScale(d)}
                stroke="green"
                strokeWidth={2}
              />
            )}
            {insulinSensitivityII &&
              insulinSensitivityII.map((d, i) => (
                <Circle
                  key={`circle-insulinSensitivityII-${i}`}
                  cx={xScale(i + 1) || 0}
                  cy={yScale(d)}
                  r={4}
                  fill="green"></Circle>
              ))}
          </Group>
        </svg>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Ratio I (purple) e Ratio II (green)</Typography>
      </Grid>
    </Grid>
  );
};

export default InsulinSensitivityChart;
