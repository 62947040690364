import {ENDPOINT_TIME_OPTIONS} from '../../../constants';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, CircularProgress} from '@mui/material';
import {BloodGlucoseChart, InsulinAndMealsChart} from '../../../components';
import {participantsService} from '../../../api';
import {useParams} from 'react-router-dom';
import {BolusAndTreatmentChart} from '../../../components/charts/bolus-and-treatment/BolusAndTreatment';

const MemoizedBloodGlucoseChart = React.memo(BloodGlucoseChart);
const MemoizedInsulinAndMealsChart = React.memo(InsulinAndMealsChart);
const MemoizedBolusAndTreatmentChart = React.memo(BolusAndTreatmentChart);

export const GraphsWrapper = () => {
  const {id} = useParams();
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    sessionStorage.getItem('sessionTimeFrame') || ENDPOINT_TIME_OPTIONS.THREE_HOURS,
  );
  const [param, setParam] = useState<string>(`${id}?timeframe=${selectedDateRange}`);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [crosshairTime, setCrosshairTime] = useState<number | null>(null);

  const {
    data: graphsData,
    isLoading: isGraphsDataLoading,
    isFetching: isGraphsDataFetching,
    refetch: refetchGraphsData,
    isUninitialized,
  } = participantsService.useGetDynamicScreenDataQuery({
    params: {id: param},
  });

  useEffect(() => {
    setParam(`${id}?timeframe=${selectedDateRange}`);
  }, [id, selectedDateRange]);

  useEffect(() => {
    if (!isUninitialized) {
      refetchGraphsData().then(response => {
        console.log('Fetched Data:', response); //veriy if data is updated
      });
    }
  }, [param, isUninitialized, refetchGraphsData]);

  const refetchTeam = useCallback(() => {
    if (!isUninitialized) {
      refetchGraphsData().then(response => {
        console.log('Auto-refetch Data:', response); // verify refetch
      });
    }
  }, [isUninitialized, refetchGraphsData]);

  // set automatic update interval
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      refetchTeam();
    }, 300000); // 5 min = 300000

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [refetchTeam]);

  // TimeFrame selector
  const handleChange = useCallback((event: SelectChangeEvent) => {
    const newTimeFrame = event?.target?.value as string;
    sessionStorage.setItem('sessionTimeFrame', newTimeFrame);
    setSelectedDateRange(newTimeFrame);
  }, []);

  if (isGraphsDataLoading || isGraphsDataFetching) {
    return (
      <div style={{width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Box sx={{width: 200, marginTop: 5}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Time Frame</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDateRange}
            label="Time Frame"
            onChange={handleChange}>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>Hour</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 days</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>Week</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 weeks</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>Month</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>All time</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {graphsData && (
        <div>
          <MemoizedBloodGlucoseChart
            {...graphsData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
          />
          <MemoizedInsulinAndMealsChart
            {...graphsData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
          />
          <MemoizedBolusAndTreatmentChart
            {...graphsData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
          />
        </div>
      )}
    </div>
  );
};
